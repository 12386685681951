import React from 'react'
import { Link } from 'gatsby'

const Navbar = () => (
  <nav className="navbar is-transparent">
    <div className="container">
      <div className="navbar-brand">
        <Link to="/" className="navbar-item">
          <span id="site-title" className="title is-3">Minimalistic</span>
        </Link>
        <a role="button" class="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="nav">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div id="nav" className="navbar-menu">
      <div className="navbar-start">
        <Link className="navbar-item" to="/about">
          About
        </Link>
        <Link className="navbar-item" to="/contact">
          Contact
        </Link>
      </div>
      </div>
    </div>
  </nav>
)

export default Navbar
